.evening{
    width: 100%;
    height: 100vh;
    margin-top: 100px;
}
.even{
    margin-left: 100px;
    margin-right:100px;
    /* border: 1px solid rebeccapurple; */
    display: flex;
    align-items: center;
    height: 100%;
}
.ev-left{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 50%;
}
.ev-top{
    height: 500px;
    width: 500px;
    position: relative;
    /* border: 1px solid yellow; */
}
.ev-top img{
    width: 100%;
    height: 100%;
}
.ev-top span{
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    font-size: 20px;
    letter-spacing: 10px;
}
.ev-bottom{
    display: flex;
    gap: 20px;
}
.ev-bottom img{
    width: 200px;
    height: 200px;
}
.ev-right{
    display: flex;
    flex-direction: column;
    /* border: 1px solid green; */
    width: 50%;
    row-gap:100px;
}
.ev-right-top{
    height: 25%;
    gap: 20px;
    width: 75%;
    display: flex;
    flex-direction: column;
    margin-left: 100px;
}
.ev-right-top p{
    color: #fff;
    font-weight: lighter;
    margin-top: 20px;
    margin-bottom: 20px;
    letter-spacing: 1px;
}
.ev-right-top h3{
    font-size: 30px;
    font-weight: lighter;
    letter-spacing: 10px;
    text-transform: uppercase;
    color: rgb(121, 16, 74);
}
.ev-right-top span{
    font-weight: lighter;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px 25px 10px 25px;
    border: 1px solid #ffff;
    letter-spacing: 10px;
    color: #fff;
    margin-bottom: 20px;
    cursor: pointer;
}

.ev-right-bottom{
    height:400px;
    width: 500px;
    position: relative;
    /* border: 1px solid yellow; */
}
.ev-right-bottom img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.ev-right-bottom span{
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    font-size: 20px;
    letter-spacing: 10px;
}