.wedding{
    height: 100vh;
    width: 100%;
    margin-top: 100px;
    position: relative;
}
.wedding h3{
    font-size: 30px;
    font-weight: lighter;
    letter-spacing: 10px;
    text-transform: uppercase;
    color: rgb(121, 16, 74);
    text-align: center;
}
.wedding-cover{
    width: 100%;
    height: 75%;
    margin-top: 50px;
    position: relative;
}
.wedding-cover span{
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    font-size: 20px;
    letter-spacing: 10px;
}
.wedding-cover img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.weding-bottom{
    display:flex;
    padding-left: 100px;
    padding-right:100px;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap:50px;
    position: absolute;
    bottom: -20%;
    /* border: 1px solid rebeccapurple; */
}
.wedding-text{
    width: 30%;
}
.wedding-text p{
    color: #fff;
    font-weight: lighter;
    margin-bottom: 25px;
    line-height: 25px;
    letter-spacing: 1px;
}
.wedding-text span{
    font-weight: lighter;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px 25px 10px 25px;
    border: 1px solid #ffff;
    letter-spacing: 10px;
    color: #fff;
    margin-bottom: 20px;
    cursor: pointer;
    
}
.wedding-bottom-img1{
    width:300px;
    height: 300px;
    position: relative;
}
.wedding-bottom-img1 span{
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    font-size: 20px;
    letter-spacing: 10px;
}
.wedding-bottom-img1 img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.wedding-bottom-img2{
    width: 300px;
    position: relative;
}
.wedding-bottom-img2 img{
    width: 100%;
}
.wedding-bottom-img2 span{
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    font-size: 20px;
    letter-spacing: 10px;
}