.hero{
    width: 100%;
    height: 100vh;
    position: relative;
}
.main-hero{
    position: relative;
    width: 100%;
    height: 100%;
}
.hero-img{
    width: 100%;
    height: 100%;
    object-fit:cover;
}
.hero-img img{
    width: 100%;
    height: 100%;
}
.hero-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    color: #fff;
    width: 100%;
}
.hero-text h1{
    font-size:50px;
    font-weight: 100;
    text-transform: uppercase;
    word-spacing: 20px;
    letter-spacing: 10px;
}
.hero-text h3{
    font-size:25px;
    font-weight: lighter;
    text-transform: uppercase;
    word-spacing: 20px;
    letter-spacing: 10px;
}
.hero-text .btn{
    margin-top: 25px;
}
.hero-text>:nth-child(3)>span{
    font-weight: lighter;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px 25px 10px 25px;
    border: 1px solid #ffff;
    letter-spacing: 10px;
}