.gala{
    height: 100vh;
    width: 100%;
    margin-top: 300px;
    color: #fff;
}
.gala-gala{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-left: 100px;
    padding-right: 100px;
    gap: 25px;
}
.gala-left{
    width:50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 25px;
}
.gala-left  h3{
    font-size: 30px;
    font-weight: lighter;
    letter-spacing: 10px;
    text-transform: uppercase;
    color: rgb(121, 16, 74);
    text-align: center;
    float: right;
}

.gala-left-img,.gala-right-img {
    width:500px;
    height: auto;
    position: relative;
}
.gala-left-img img{
    width: 100%;
    height: 100%;
}
.gala-right-img img{
    width: 100%;
    height: 100%;
}
.gala-left-img span{
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    font-size: 20px;
    letter-spacing: 10px;
}
.gala-right{
    width:50%;
}
.gala-right-img span{
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    font-size: 20px;
    letter-spacing: 10px;
}
.gala-right-bottom{
    width: 75%;
}
.gala-right-bottom p{
    color: #fff;
    font-weight: lighter;
    margin-bottom: 25px;
    line-height: 25px;
    letter-spacing: 1px;
}
.gala-right-bottom span{
    font-weight: lighter;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px 25px 10px 25px;
    border: 1px solid #ffff;
    letter-spacing: 10px;
    color: #fff;
    margin-bottom: 20px;
    cursor: pointer;
}